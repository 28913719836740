<template>
  <div>
    <h3 class="center">Мои финансы</h3>

    <div class="page-my-finance heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetreive" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <table class="t_table">
        <tr>
          <th>Дата</th>
          <th>Начислено</th>
          <th>Выплачено</th>
          <th>Пояснение</th>
        </tr>

        <tr v-if="preAccrued || prePaid">
          <td></td>
          <td class="R">
            {{ preAccrued ? preAccrued : "" }}
          </td>
          <td class="R">
            {{ prePaid ? prePaid : "" }}
          </td>
          <td class="L">Баланс на {{ dateFormatVarious(date_begin) }}</td>
        </tr>

        <template v-if="this.report.length > 0">
          <tr v-for="(row, y) in report" :key="y">
            <td class="center">{{ dateFormatVarious(row.date) }}</td>
            <td class="R">{{ numFormat(row.accrued) }}</td>
            <td class="R">{{ numFormat(row.paid) }}</td>
            <td class="L">{{ commentDecorate(row.comment, row.date_begin, row.date_end) }}</td>
          </tr>

          <tr class="summary">
            <td>Итого</td>
            <td class="R">{{ numFormat(accruedTotal) }}</td>
            <td class="R">{{ numFormat(paidTotal) }}</td>
            <td class="R">{{ numFormat(balanceAccruedPaid) }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="300">нет данных по выбранным параметрам</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import { numFormat } from "@/components-js/numberFormat";
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
import Loader from "@/components/Loader.vue";
const loStorage = new lS();

export default {
  name: "PeopleWindow",
  components: {
    Loader,
    DateSelector,
  },

  data() {
    return {
      errMessage: null,
      balancePrev: [0],
      preAccrued: 0,
      prePaid: 0,
      report: [],
      person: {},
      date_begin: null,
      date_end: null,

      loading: false,

      date: null,
      accrued: null,
      paid: null,
      comment: null,

      dataChangeFlag: false,
      rowPrefix: "row",
      rowSelected: null,
    };
  },

  mounted() {
    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    if (!this.date_begin || !this.date_end) {
      this.date_begin = dateFormatJS(new Date(new Date().setDate(1)));
      this.date_end = dateFormatJS(new Date());
    }

    this.tableDataRetreive();
  },

  computed: {
    accruedTotal: function() {
      let sum = 0;
      this.report.forEach(el => (sum += el.accrued ? el.accrued : 0));
      sum += this.preAccrued;
      return sum;
    },

    paidTotal: function() {
      let sum = 0;
      this.report.forEach(el => (sum += el.paid ? el.paid : 0));
      sum += this.prePaid;
      return sum;
    },

    balanceAccruedPaid: function() {
      return this.accruedTotal - this.paidTotal;
    },
  },

  methods: {
    async tableDataRetreive() {
      let balancePrev = await request("/api/money/saldoforperiod", "POST", {
        path: this.$route.path,
        id_people: this.$root?.user?.uid,
        date_begin: "2000-01-01",
        date_end: dateFormatJS(
          new Date(new Date(this.date_begin).setDate(new Date(this.date_begin).getDate() - 1))
        ),
      });

      this.preAccrued = balancePrev[0].accruedTotal - balancePrev[0].paidTotal;
      this.preAccrued = this.preAccrued < 0 ? 0 : this.preAccrued;
      this.prePaid = balancePrev[0].paidTotal - balancePrev[0].accruedTotal;
      this.prePaid = this.prePaid < 0 ? 0 : this.prePaid;

      this.report = await request(
        "/api/money/movementforperiod",
        "POST",
        {
          id_people: this.$root?.user?.uid,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
    },

    numFormat(n) {
      return numFormat(n);
    },

    dateFormatVarious(d, w) {
      return dateFormatVarious(d, w, { day: "numeric", month: "numeric", year: "2-digit" });
    },

    commentDecorate(comment, date_begin, date_end) {
      // if period is empty
      if (!date_begin || !date_end) return comment;
      // otherwise add it
      return (
        comment +
        " (" +
        this.dateFormatVarious(date_begin, 1) +
        " - " +
        this.dateFormatVarious(date_end, 1) +
        ")"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1300px) {
  .page-my-finance {
    flex-direction: column;
  }
}
.summary td {
  background-color: white;
}

tr.accrued_auto td {
  background-color: #cccccc;
}

tr.accrued_manual td {
  background-color: #d9ead3;
}

div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
.middle {
  vertical-align: middle;
}
.widthfit {
  width: fit-content;
}
.num {
  width: 5rem;
  text-align: right;
}
.lnkDel {
  cursor: pointer;
}
.lnkDel:hover {
  color: red;
  background-color: unset;
}
.lnkUpd {
  cursor: pointer;
}
.lnkUpd:hover {
  color: #406d96;
  background-color: unset;
}
tr.row:hover td {
  cursor: pointer;
  background-color: beige;
}
tr.rowSelected td {
  background-color: beige;
}
</style>
